const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../components/error.svelte"),
	() => import("../../../src/routes/index.svelte"),
	() => import("../../../src/routes/basic-alliances/index.svelte"),
	() => import("../../../src/routes/basic-alliances/alliances.svelte"),
	() => import("../../../src/routes/basic-alliances/invite.svelte"),
	() => import("../../../src/routes/default-plugins/basic-spaceships-marketplace/index.svelte"),
	() => import("../../../src/routes/agent-service.svelte"),
	() => import("../../../src/routes/withdrawals.svelte"),
	() => import("../../../src/routes/highscores.svelte"),
	() => import("../../../src/routes/alliances.svelte"),
	() => import("../../../src/routes/freetoken.svelte"),
	() => import("../../../src/routes/external/basic-alliances.svelte"),
	() => import("../../../src/routes/settings.svelte"),
	() => import("../../../src/routes/cashout.svelte"),
	() => import("../../../src/routes/plugins.svelte"),
	() => import("../../../src/routes/recover.svelte"),
	() => import("../../../src/routes/debug.svelte"),
	() => import("../../../src/routes/stats.svelte"),
	() => import("../../../src/routes/help.svelte"),
	() => import("../../../src/routes/logs.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]],

	// src/routes/basic-alliances/index.svelte
	[/^\/basic-alliances\/?$/, [c[0], c[3]], [c[1]]],

	// src/routes/basic-alliances/alliances.svelte
	[/^\/basic-alliances\/alliances\/?$/, [c[0], c[4]], [c[1]]],

	// src/routes/basic-alliances/invite.svelte
	[/^\/basic-alliances\/invite\/?$/, [c[0], c[5]], [c[1]]],

	// src/routes/default-plugins/basic-spaceships-marketplace/index.svelte
	[/^\/default-plugins\/basic-spaceships-marketplace\/?$/, [c[0], c[6]], [c[1]]],

	,

	// src/routes/agent-service.svelte
	[/^\/agent-service\/?$/, [c[0], c[7]], [c[1]]],

	// src/routes/withdrawals.svelte
	[/^\/withdrawals\/?$/, [c[0], c[8]], [c[1]]],

	// src/routes/highscores.svelte
	[/^\/highscores\/?$/, [c[0], c[9]], [c[1]]],

	// src/routes/alliances.svelte
	[/^\/alliances\/?$/, [c[0], c[10]], [c[1]]],

	// src/routes/freetoken.svelte
	[/^\/freetoken\/?$/, [c[0], c[11]], [c[1]]],

	// src/routes/external/basic-alliances.svelte
	[/^\/external\/basic-alliances\/?$/, [c[0], c[12]], [c[1]]],

	// src/routes/settings.svelte
	[/^\/settings\/?$/, [c[0], c[13]], [c[1]]],

	// src/routes/cashout.svelte
	[/^\/cashout\/?$/, [c[0], c[14]], [c[1]]],

	// src/routes/plugins.svelte
	[/^\/plugins\/?$/, [c[0], c[15]], [c[1]]],

	// src/routes/recover.svelte
	[/^\/recover\/?$/, [c[0], c[16]], [c[1]]],

	// src/routes/debug.svelte
	[/^\/debug\/?$/, [c[0], c[17]], [c[1]]],

	// src/routes/stats.svelte
	[/^\/stats\/?$/, [c[0], c[18]], [c[1]]],

	// src/routes/help.svelte
	[/^\/help\/?$/, [c[0], c[19]], [c[1]]],

	// src/routes/logs.svelte
	[/^\/logs\/?$/, [c[0], c[20]], [c[1]]]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];